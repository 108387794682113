// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  background-color: rgb(247, 247, 247);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 0.8em;
  width: 100%;
  place-items: center;
  padding: 3.5% 7%;
}

.copyrights {
  text-align: center;
}

.social-media {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.contact {
  display: flex;
  flex-direction: column;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,aAAa;EACb,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA,kBAAkB;AAClB;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;EACV;AACF","sourcesContent":[".Footer {\n  background-color: rgb(247, 247, 247);\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  font-size: 0.8em;\n  width: 100%;\n  place-items: center;\n  padding: 3.5% 7%;\n}\n\n.copyrights {\n  text-align: center;\n}\n\n.social-media {\n  display: flex;\n  flex-direction: row;\n  gap: 1em;\n}\n\n.contact {\n  display: flex;\n  flex-direction: column;\n}\n\n/* Media queries */\n@media screen and (max-width: 768px) {\n  .Footer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
