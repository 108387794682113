import { Link, useLocation } from "react-router-dom";
// import Logo from "../images/logo.png";
import Logo from "../images/MM24LogoTpBg.png"
import "../styles/Navbar.css";
import React, { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { FiMenu } from "react-icons/fi";
import ScrollToTop from "./ScrollToTop";

const ACTIVE_COLOR = '#0c5298';

function Navbar() {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen);
    };

    const location = useLocation();

    const activator = (path) => ({ color: (location.pathname === path && ACTIVE_COLOR) })

    // const [divHeight, setDivHeight] = useState(0);

    // const ref = useRef(null);

    // useEffect(() => {
    //   setDivHeight(ref.current.clientHeight);
    //   console.log("height: ", ref.current.clientHeight);
    //   console.log("width: ", ref.current.clientWidth);
    // }, []);

    return (
        <div>
      <div className="Navbar">
        <div className="Navbar__left">
          <ScrollToTop />
          <div className="navlogo"> <Link to="/" >
            <img src={Logo} alt="MumbaiMUN Logo"></img>
            </Link>
          </div>
          <Link to="/" className="navlogo"></Link>
        </div>
        <div className="Navbar__right">
          <IconContext.Provider
            value={{ color: "rgb(90, 90, 90)", className: "global-class-name" }}
          >
            <button className="menuToggler" onClick={handleToggle}>
              {navbarOpen ? <VscChromeClose /> : <FiMenu />}
            </button>
          </IconContext.Provider>
          <ul className={`Navbar__links ${navbarOpen ? " showLinks" : ""}`}>
            <Link style={activator("/")} to="/">Home</Link>
            <Link style={activator('/about')} to="/about">About Us</Link>
            <Link style={activator('/events')} to="/events">Events</Link>
            <Link style={activator('/conference')} to="/conference">Conference</Link>
            {/* <Link style={activator('/Register')} to="/Register">Register</Link> */}

            {/* <a rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSd6FT4y-nTibMKbTxEOeamQ_0y34rx0yWjyFrKlzAU6binRnQ/viewform" target="_blank">Registration</a> */}
            <Link style={activator('/committees')} to="/committees">Committees</Link>
          </ul>
        </div>
      </div>
      <div className="spacer"></div>
    </div>
    );
}

export default Navbar;