// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding: 2em 0em;
}

.time {
  font-family: "Unbounded", sans-serif;
  color: #0f4c90;
  font-weight: 700;
  font-size: 3em;
}

.text {
  font-size: 1.2em;
}

.unit:nth-of-type(even) {
  background-color: rgb(226, 226, 226);
}

.timer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.started {
  text-align: center;
  width: 100%;
  padding: 2rem;
  font-family: "Unbounded";
  text-transform: uppercase;
  font-size: 2rem;
  background-color: rgba(192, 193, 230, 0.452);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Timer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,0BAA0B;EAC1B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,wBAAwB;EACxB,yBAAyB;EACzB,eAAe;EACf,4CAA4C;EAC5C,kCAAkC;AACpC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".unit {\n  display: grid;\n  grid-template-columns: 1fr;\n  place-items: center;\n  padding: 2em 0em;\n}\n\n.time {\n  font-family: \"Unbounded\", sans-serif;\n  color: #0f4c90;\n  font-weight: 700;\n  font-size: 3em;\n}\n\n.text {\n  font-size: 1.2em;\n}\n\n.unit:nth-of-type(even) {\n  background-color: rgb(226, 226, 226);\n}\n\n.timer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n\n.started {\n  text-align: center;\n  width: 100%;\n  padding: 2rem;\n  font-family: \"Unbounded\";\n  text-transform: uppercase;\n  font-size: 2rem;\n  background-color: rgba(192, 193, 230, 0.452);\n  animation: fadeIn 0.5s ease-in-out;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
