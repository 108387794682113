import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Timer from "../components/Timer";

// import dhruvthakker from '../images/eb/Dhruv Thakker.JPG'
// import aryaveersingh from "../images/eb/Aryaveer Singh.jpg";
// import marshaabalani from"../images/eb/Marshaa Balani.jpg";
// import yashmudaliyar from "../images/eb/Yash 1.jpeg";
// import athangshinde from "../images/eb/Athang Shinde.JPG";
// import mohilmehra from "../images/eb/Mohil Mehra.JPG"
// import aashayinamdar from "../images/eb/Aashay Inamdar.jpg";
// import manavsingh from "../images/eb/manavsingh.jpg";

import { FaGreaterThan, FaLessThan } from "react-icons/fa";
// Committees and their agendas
const data = [
  {
    color: "#ce2a26",
    Committee: "UNSC",
    "Agenda 1":
      " Ensuring Peace and Security in Niger and the ECOWAS Region ",
    "Agenda 2": " Addressing Rising Tensions Surrounding the Nile Dam Dispute",
  },
  {
    color: "#672578",
    Committee: "AIPPM",
    "Agenda 1": "Ensuring the Autonomy of Law Enforcement Agencies",
    "Agenda 2": "Deliberation on Decentralisation of Power with Special Emphasis on Powers of the Union Territories",
  },
  {
    Committee: "UNGA - ESS ",
    "Agenda 1":
      "6th Session - Soviet Invasion of Afghanistan (Freeze date: 10th January 1980) ",
    // "Agenda 2": "To address the cultural extinction of displaced communities.",
    color: "#cc5481",
  },
  {
    Committee: "UNW",
    b: "(School)",
    "Agenda 1":
      "Addressing Gender-Based Institutional and Legal Barriers.",
    "Agenda 2": "Addressing Gender Equality and Inclusivity in Sports.",
    color: "#61aa9e",
  },
  {
    Committee: "IMF",
    b: "(College)",
    "Agenda 1":
      "Discussing the Adoption of National Currency in Global Trade ",
    "Agenda 2":
      "Assessing the Impact of Digitalisation on Cross-Border Economic Activity.",
    color: "#c7481f",
  },
  {
    textColor: "#251e24",
    Committee: "UNICEF",
    b: "(School)",
    "Agenda 1":
      "Establishing a Comprehensive Child Welfare Framework for Orphans.",
      "Agenda 2":
      "Advancing Juvenile Justice Reform for the Protection of Children's Rights",
    color: "#fec31f",
  },
];

const styles = {
  comSelector: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 1fr",
    placeItems: "center",
  },
  arrow: { marginTop: 4, cursor: "pointer" },
};

const ArrowNav = () => {
  const [com, setCom] = useState(0);

  useEffect(() => console.log(com), [com]);

  const handleClick = (e) => {
    if (e === "dec" && com === 0) return;
    else if (e === "inc" && com === data.length - 1) return;
    else setCom((prev) => (e === "inc" ? prev + 1 : prev - 1));
  };

  const Agenda = ({ agenda }) => (
    <div
      style={{
        fontWeight: "bold",
        color: "#231E20",
        padding: 10,
        border: `2px solid ${data[com].color}`,
        backgroundColor: "white",
        borderRadius: 20,
        textTransform: "uppercase",
        // width: "60ch",
        margin: "auto",
        textAlign: "center",
      }}
    >
      {agenda}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: data[com].color,
        padding: 40,
        borderRadius: 30,
      }}
    >
      <div id="comSelector" style={{ ...styles.comSelector, color: "white" }}>
        {com === 0 && <div></div>}
        {com > 0 && (
          <FaLessThan
            onClick={() => handleClick("dec")}
            id="dec"
            style={styles.arrow}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 50,
            justifyContent: "center",
          }}
        >
          <h3 style={{ textAlign: "center", userSelect: "none" }}>
            {data[com].Committee}
          </h3>
          {data[com].b && (
            <h3 style={{ textAlign: "center", userSelect: "none" }}>
              {data[com].b}
            </h3>
          )}
        </div>
        {com < data.length - 1 && (
          <FaGreaterThan
            onClick={() => handleClick("inc")}
            id="inc"
            style={styles.arrow}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginTop: 10,
        }}
        class="agendas"
      >
        <div className="test">
          {data[com]["Agenda 1"] && <Agenda agenda={data[com]["Agenda 1"]} />}
        </div>
        <>
          {data[com]["Agenda 2"] && <Agenda agenda={data[com]["Agenda 2"]} />}
        </>
      </div>
    </div>
  );
};

export default function Committees() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>Committees and Agendas</h2>
          </div>
            {/* <div className="card-container"> */}
            <div className="card-container">
              <div className="comm-cards">
                  <div className="committee-card">
                    {/* <img src={aryaveersingh} alt="Portrait of Miheer Jain"></img> */}
                    <h2>UNSC</h2>
                    <div className="info-agenda">
                      <h3>De-escalating Tensions in Middle East with Special Emphasis on Non-state Actors</h3>
                    </div>
                  </div>
                <div className="committee-card">
                  {/* <img src={marshaabalani} alt="Portrait of Kartik Deshwal"></img> */}
                  <h2>AIPPM</h2>
                  <div className="info-agenda">
                    <h3>Reviewing the Agnipath Scheme for Military Recruitment</h3>
                    <br></br>
                    <h3>Reforms in the Indian Criminal Justice System to Uphold Rule of Law</h3>
                  </div>
                </div>
                <div className="committee-card">
                  {/* <img src={yashmudaliyar} alt="Portrait of Tushar Rajput"></img> */}
                  <h2>UNODC</h2>
                  <div className="info-agenda">
                    <h3>Reviewing the Role of Civil Society for Effective Crime Prevention</h3>
                    <br></br>
                    <h3>Combating Transnational Environmental Organized Crime</h3>
                  </div>
                </div>
                <div className="committee-card">
                  {/* <img src={athangshinde} alt="Portrait of Naiessha Nayyar"></img> */}
                  <h2>World Bank</h2>
                  <div className="info-agenda">
                    <h3>Evaluating Reconstruction Strategies for Fragile and Conflict-Affected Areas</h3>
                    <br></br>
                    <h3>Developing a Framework for Sustainable Energy Transition</h3>
                  </div>
                </div>
                <div className="committee-card">
                  {/* <img src={mohilmehra} alt="Portrait of Aarya Singh"></img> */}
                  <h2>WHO (school)</h2>
                  <div className="info-agenda">
                    <h3>Addressing Challenges in the Successful Implementation of Immunization Agenda 2030</h3>
                    <br></br>
                    <h3>Developing Strategies to Combat Infodemics in the Digital Age</h3>
                  </div>
                </div>
                <div className="committee-card">
                  {/* <img src={aashayinamdar} alt="Portrait of Navya Luhadia"></img> */}
                  <h2>UNFCCC - COP (school)</h2>
                  <div className="info-agenda">
                    <h3>Assessing the Impact of Climate Change on Small Island States</h3>
                    <br></br>
                    <h3>Advancing Climate Action in Global Food Security</h3>
                  </div>
                </div>
                <div className="committee-card-ipc">
                  {/* <img src={aashayinamdar} alt="Portrait of Navya Luhadia"></img> */}
                  <h2>IPC</h2>
                  <div className="info-agenda-ipc">

                    <h3>Journalism <br></br>
                    Photography</h3>
                  </div>
                </div>
              </div>
          {/* </div> */}
        </div>
      </div>
      </div>
      <Footer></Footer>
    {/* </div> */}
    </div>
  );
}
