import React from "react";
import "react-tabs/style/react-tabs.css";

import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";

function TabPanelVertical(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {/* height: 600, overflow: "auto" */}
      {value === index && (
        <Box sx={{ pt: 0, pb: 3, pl: 3, pr: 3 }}>
          <Typography>{children}</Typography>
        </Box>

      )}
    </div>
  );
}

function a11yPropsVertical(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        textAlign: "justify",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ minWidth: 150, borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Mumbai MUN" {...a11yPropsVertical(0)} />
        <Tab label="Mumbai" {...a11yPropsVertical(1)} />
        <Tab label="NMIMS MPSTME" {...a11yPropsVertical(2)} />
        <Tab label="ABOUT SVKM" {...a11yPropsVertical(3)} />
        <Tab label="ABOUT MUN SOCIETY" {...a11yPropsVertical(4)} />
        {/* <Tab label="LETTER FROM SG" {...a11yPropsVertical(5)} /> */}
      </Tabs>
      <TabPanelVertical value={value} index={0}>
        <h2 style={{marginBottom: '10px'}}>Mumbai MUN</h2>
        <p>
          The epitome of simulated international diplomacy, Mumbai MUN has been
          a conduit, transforming today's youth into tomorrow's leaders. With a
          wide range of UN committees to choose from, it gives delegates who
          want to discuss today's problems a chance to be a catalyst for change.
          Mumbai MUN is back again with its <b>eleventh</b> installment in 2024 as a
          three day conference on the <b><u>8th, 9th and 10th of November.</u></b>
          <br />
          <br />
          Since it was first conducted in 2012, MumbaiMUN has consistently
          outdone itself each year. The conference is an expedient platform to
          debate today's pressing global issues, comprehend new perspectives,
          build strong networks, and foster enriching relationships with fellow
          MUN-ers. It has become a medium through which delegates find a sense
          of responsibility and obtain the ability to visualize the more
          significant goal: maturing into the rational leaders of tomorrow.
          Mumbai MUN is India's biggest, most unique, and most culturally
          stimulating conference. It promises to leave you with an unforgettable
          experience that will make you want to come back for more.
        </p>
      </TabPanelVertical>
      <TabPanelVertical value={value} index={1}>
        <h2 style={{marginBottom: '10px'}}>Mumbai</h2>
        <p>
          An imbued air, a nomadic delight, seven islands for seven chakras; one
          destination- Mumbai. For some their muse, the rest, a ruse! A pearl in
          the bosom of the sea, this jewel of a city warrants spiritual
          contemplation at the mere sight of the paradox which Mumbai harbours.
          From the masses that throng the famous local trains, to the hush that
          blankets its quaint bylanes, it is no surprise that one is splashed by
          a tidal wave of primal desires that Mumbai elicits.
          <br />
          <br />
          Continuing the bipolarity, and the people's appetite for it, Mumbai
          has displayed flawless character and integrity, which is evident from
          the illustrious personas who have resided in it. The Mahatma found
          solace in its idiosyncrasy while Jamshedji Tata chose to redefine it
          with the Taj. These stalwarts and their memories were immortalised
          when Mumbai and its people refuse to bend to the will of
          nefariousness, time and again. The serenity from its spiritual
          enclaves as well as the thrill from its trade, Mumbai offers something
          in every walk of life. The innumerable temples and festivals
          representing India's culture and traditions constitute the soul of
          this city. Preaching pragmatism, Mumbai houses the oldest stock
          exchange in Asia and is residence to some of the richest personalities
          in the world.
          <br />
          <br />
          From the unassuming vada pav to seven course gourmet meals, Mumbai has
          it all. Restaurants, clubs, theatres, cinemas, shacks and stalls; this
          city caters to every taste and pocket and sports a happy-go- lucky
          candour. Take a journey of meaning, of beauty and surrender yourself
          to this enigma that basks in culture, in technology, a blend of modern
          conception and Victorian sensibilities, the ambition of the few and
          the complacence of the many and rest in the culmination that the sun
          casts with its dusky hue on the city and its waters; in the Bay of
          Bombay!
          <br />
          <br/>
          Discover Mumbai, rediscover yourself...
        </p>
      </TabPanelVertical>
      <TabPanelVertical value={value} index={2}>
        <h2 style={{marginBottom: '10px'}}>NMIMS MPSTME</h2>
        <p>
          Mukesh Patel School of Technology Management & Engineering (MPSTME) is
          an important addition to NMIMS's vision of 'Transcending Horizons'.
          MPSTME is a unique culmination o the University’s inherent
          versatility, in both Engineering and Management domains, which forges
          individuals into much needed techno-managers ready for today’s
          industry.
          <br />
          <br />
          MPSTME has been ranked 7th in "Top Emerging Colleges of Excellence" in
          the country and 5th in the state of Maharashtra by the Competition
          Success Review." It offers four-year B.Tech programmes in six branches
          and five-year MBA (Tech) programmes in five branches, offering MCA and
          Ph.D Programmes alongside.
          <br />
          <br />
          The MPSTME campus in Mumbai stands tall, symbolic of India's progress
          in the field of education. The modern state-of- the-art facilities
          such as Wi-Fi covered campus, an aesthetically designed building and
          technologically advanced classrooms and laboratories (including
          collaborative labs with Blackberry, Rexroth Bosch, etc.) ensure a
          comprehensive learning experience, provide a conducive atmosphere for
          teaching, learning & an all-round development of students. What is
          noteworthy is that besides academics, the college environment engages
          students’ co-curricular aptitude through a plethora of committees and
          societies to take part in.
          <br />
          <br />
          For more information, visit {""}
          <a
            style={{ textDecoration: "underline" }}
            href="http://engineering.nmims.edu/"
          >
            http://engineering.nmims.edu/
          </a>
        </p>
      </TabPanelVertical>
      <TabPanelVertical value={value} index={3}>
        <h2 style={{marginBottom: '10px'}}>About SVKM</h2>
        <p>
          Shri Vile Parle Kelavani Mandal is a Public Charitable Trust
          registered under the Society’s Registration Act and Bombay Public
          Trust Act. From its humble beginnings in 1934, when it took over the
          Rashtriya Shala (a school established in 1921 in the wake of the
          National Movement), the Mandal today has grown into a big educational
          complex that imparts high-level education to more than 35,000
          students.
          <br />
          <br />
          Patriotic fervour, selfless service and a spirit of indigenous
          enterprise mark the ethos of the Mandal. These values permeate all the
          institutions set up by the Mandal and are the guiding principles for
          all of them.
          <br />
          <br />
          From its beginning with the Swadeshi Movement, the Mandal has now
          grown into an educational foundation promoting global thinking
          consistent with national interest and promoting the values,
          professionalism, social sensitivity and dynamic entrepreneurship.
        </p>
      </TabPanelVertical>
      <TabPanelVertical value={value} index={4}>
        <h2 style={{marginBottom: '10px'}}>About MUN Society</h2>
        <p>
          MUN Society of MPSTME has been the proud organisers of Mumbai MUN
          every year, with a dedicated Organising Committee of 100+ students who
          strive to perfection in all the activities undertaken by the Society.
          Branching into various distinct departments, the OC of MUN society are
          focused on honing the skill set expected of them, and also share a
          deep rooted commitment to the ideals of Mumbai MUN. Over the years,
          MUN Society MPSTME has been instrumental towards conducting IntraMUNs
          for college students in MPSTME and awareness programs on important
          International calendar dates like Women’s Day and UN Day. With Mumbai
          MUN 2024, the Society continues to grow and excel in all avenues of
          organising and management.
        </p>
      </TabPanelVertical>
      {/* <TabPanelVertical value={value} index={5}>
        <h2>Letter from SG</h2>
      </TabPanelVertical> */}
    </Box>
  );
}

export default function About() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>About Us</h2>
          </div>
          <div>
            {/* <HorizontalTabs /> */}
            <VerticalTabs />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
