import React from "react";
import { VscMail } from "react-icons/vsc";
import { BsTelephone } from "react-icons/bs";
import "../styles/Footer.css";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaHeart } from "react-icons/fa";
import { IconContext } from "react-icons";

function Footer() {
  return (
    <div className="Footer">
      <div className="copyrights">
        Copyrights © 2024
        <br />
        All Rights Reserved by Mumbai MUN 2024.
        <br />
        <br />
        Made with{" "}
        <IconContext.Provider value={{ color: "#f92f60" }}>
          <span>
            <FaHeart />
          </span>
        </IconContext.Provider>{" "}
        by Tech
      </div>
      <div className="contact">
        <div>
          <VscMail />{" "}
          <a href="mailto: info@mumbaimun.com"> info@mumbaimun.com </a>
        </div>
        <br />
        <div>
          <BsTelephone />
          <a href="tel:(+91) 9359215029 "> (+91) 93592 15029 </a>
        </div>
        <br />
        <div>
          {/*PR :*/}
          <BsTelephone />
          <a href="tel:(+91) 9867499046 "> (+91) 98674 99046 </a>
        </div>
        <br />
      </div>
      <div className="social-media">
        <a href="https://www.facebook.com/munsociety/">
          <FaFacebook size={20} />
        </a>
        <a href="https://www.instagram.com/mumbaimun">
          <FaInstagram size={20} />
        </a>
        <a href="https://www.linkedin.com/company/mun-society-mpstme">
          <FaLinkedinIn size={20} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
