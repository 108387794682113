import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>Last updated: 3/11/2023</p>

            <h2>Information Collection and Use</h2>
            <p>
                For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Email address. The information that we request will be retained on your device and is not collected by us in any way.
                The app does use third-party services that may collect information used to identify you.
                Link to the privacy policy of third-party service providers used by the app

                <a href='https://policies.google.com/privacy'>Google Play Services</a>

            </p>

            <h2>Log Data</h2>
            <p>
                Whenever you use our Service, in case of an error in the app we collect data and information (through third-party products)
                on your phone called Log Data. This Log Data may include information such as your device Internet Protocol
                ("IP") address, device name, operating system version, the configuration of the app when utilizing my Service,
                the time and date of your use of the Service, and other statistics.
            </p>

            <h2>Cookies</h2>
            <p>
                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that
                use "cookies" to collect information and improve their services. You have the option to either accept or refuse these
                cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able
                to use some portions of this Service.
            </p>

            <h2>Service Providers</h2>
            <p>
                We may employ third-party companies and individuals due to the following reasons:
            </p>
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <br></br>
            <p>
                We want to inform users of this Service that these third parties have access to their Personal Information.
                The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose
                or use the information for any other purpose.
            </p>



            <h2>Security</h2>
            <p>
                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable
                means of protecting it. But remember that no method of transmission over the internet, or method of electronic
                storage is 100% secure and reliable, and we cannot guarantee its absolute security.
            </p>

            <h2>Links to Other Sites</h2>
            <p>
                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site.
                Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy
                of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices
                of any third-party sites or services.
            </p>

            <h2>Children's Privacy</h2>
            <p>
                These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
                information from children under 13 years of age. In the case we discover that a child under 13 has provided us
                with personal information, we immediately delete this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal information, please contact us so that we will be able
                to do the necessary actions.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes.
                This policy is effective as of 2022-12-19
            </p>


            <h2>Contact Us</h2>
            <p>
                If you have any questions or suggestions about our Privacy Policy, contact us at
                tech@munsocietympstme.com
            </p>

            <h2>Terms and Conditions</h2>
            <p>
                By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to MUNSoc MPSTME.
            </p>

            <p>
                MUNSoc MPSTME is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.
            </p>

            <p>
                The Delego app stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the app secure. Therefore, we recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Delego app won’t work properly or at all.
            </p>

            <p>
                The app does use third-party services that declare their Terms and Conditions.
                <a href="https://policies.google.com/terms">Link to Terms and Conditions of third-party service providers used by the app</a>:
            </p>
            <ul>
                <li>Google Play Services</li>
            </ul>

            <p>
                You should be aware that there are certain things that MUNSoc MPSTME will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but MUNSoc MPSTME cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
            </p>

            <p>
                If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e., region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.
            </p>

            <p>
                Along the same lines, MUNSoc MPSTME cannot always take responsibility for the way you use the app i.e., You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, MUNSoc MPSTME cannot accept responsibility.
            </p>

            <p>
                With respect to MUNSoc MPSTME’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. MUNSoc MPSTME accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
            </p>

            <p>
                At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for both systems (and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. MUNSoc MPSTME does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you. We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
            </p>


            <h2>Changes to This Terms and Conditions</h2>
            <p>
                We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes.
                These terms and conditions are effective as of 2022-12-19
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions or suggestions about my Terms and Conditions, contact us at
                tech@munsocietympstme.com.
            </p>

        </div>
    );
};

export default PrivacyPolicy;
