import React from "react";
import "react-tabs/style/react-tabs.css";

import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";
// Panelists Images
// import Aayush from "../images/panelists/aayush.png";
// import Amar from "../images/panelists/amar.png";
// import Harshil from "../images/panelists/harshil.png";

export default function Events() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>Events</h2>
          </div>
          <div>
            {/*<VerticalTabs />*/}
           <div class="panelists">
              {/* <h2>Panel Discussion</h2>

              <div class="panelist panelist-1">
                <div class="panelist-info">
                  <h3>Harshil Karia</h3>
                  <h4>Founder, Schbang & Co-founder, Level Supermind</h4>
                  <p>
                    He has conquered the marketing industry. He runs the only
                    global Indian company in the advertisement and communication
                    industry , employing over 1200 individuals, and has achieved
                    profitability in just over 5 years
                  </p>
                </div>
                <img src={Harshil} alt="Portrait of Harshil Karia"></img>
              </div>
              <div class="panelist panelist-2">
                <div class="panelist-info">
                  <h3>Captain Amar Johri</h3>
                  <h4>Director of Aman Aviations</h4>
                  <p>
                    Captain Aman Johri is the CEO of an Aerospace & Defence Firm
                    based in Mumbai. He develops next-generation weapons
                    technology for the Indian Armed Forces. He's also fondly
                    known as India's Tony Stark.
                  </p>
                </div>
                <img src={Amar} alt="Portrait of Amar Johri"></img>
              </div>
              <div class="panelist panelist-3">
                <div class="panelist-info">
                  <h3>Aayush Anand</h3>
                  <h4>Co-founder, Level Supermind</h4>
                  <p>
                    An IIT Madras alumni who worked with ITC, P&G, CERN and has
                    successfully co- founded Level Supermind at the age of 23.
                  </p>
                </div>
                <img src={Aayush} alt="Portrait of Aayush Anand"></img>
              </div> */}

            <h1 className="comingsoon">Coming Soon... </h1>
            </div> 
            

            
          </div>
        </div>
      </div>
  
      <Footer ></Footer>
    </div>
  );
}
