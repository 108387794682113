import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

import Index from "./pages/Index";
import About from "./pages/About";
import Committees from "./pages/Committees";
import Conference from "./pages/Conference";
import Events from "./pages/Events";
import Register from "./pages/Register";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Priority from "./pages/Priority";
import PrivacyPolicy from "./pages/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/committees",
    element: <Committees />,
  },
  {
    path: "/conference",
    element: <Conference />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/priority",
    element: <Priority />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
